<template>
  <div class="mainContainer">
    <div class="container">
      <div class="mt-5 col-12">

      </div>
      <div class="mt-5">
        <div class="containerRequest">
          <h2>
            Pedido <strong> Banco do Brasil </strong>
          </h2>
        </div>
        <checkService :itemRequest.sync="$store.getters.getItemRequest"/>
        <hr />
        <div class="col-12 d-flex align-items-center justify-content-between">
          <div class="buttonsContainer">
            <router-link :to="'/'" tag="button">
                <img src="@/assets/image/pedidos/guardar.png" alt="guardar" />
                Guardar Pedido
            </router-link>
          </div>
          <!-- <button class="btnFinish" @click="finalizar" :disabled="loadSend"> -->
          <button class="btnFinish" data-toggle="modal" data-target="#modaComfirmBB" :disabled="loadSend">
            <div v-if="!loadSend">
              <img
                src="@/assets/image/pedidos/finalizar.png"
                alt="finalizar"
                class="pr-2"
              />
              Finalizar Pedido
            </div>

            <half-circle-spinner
              :animation-duration="1000"
              :size="30"
              color="#fff"
              v-else
            />
          </button>
        </div>
      </div>
    </div>
    <modalComfirm @finishService="finalizar" />
    <!-- <modalDiscard /> -->
  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import HTTP from '@/api_system'
import checkService from '@/components/finalizar_pedidos/checkServiceBB.vue'
import modalComfirm from '../components/finalizar_pedidos/container_servico/modalComfirm.vue'
export default {
  components: {
    checkService,
    HalfCircleSpinner,
    modalComfirm
  },
  computed: {
    ...mapGetters(['getItemRequest'])
  },
  data () {
    return {
      settings: {
        autoplay: false
      },
      loadSend: false,
      valuesPays: [],
      getpedidosCurrent: {}
    }
  },
  mounted () {
    const link = this.getItemRequest.ordersServices[0].link
    if (link) {
      this.openPopup()
    }
  },
  methods: {
    ...mapActions(['finishServiceBB']),
    async finalizar () {
      try {
        await this.finishServiceBB(this.$store.getters.getItemRequest.id)
        $('#modaComfirmBB').modal('hide')
        this.toast('Serviço finalizado com sucesso!', this.$toast)
        this.$router.push('/pedidos')
      } catch (error) {
        this.toast('Erro', this.$toast.error)
        console.log(error)
      }
    },
    openPopup () {
      const h = 450
      const w = 900
      const left = screen.width / 2 - w / 2
      const top = screen.height / 2 - h / 2

      window.open(
        `${this.getItemRequest.ordersServices[0].link}`,
        'winname',
        `directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,top=${top},left=${left},width=${w},height=${h}`
      )
      window.close()
    }
  }
}
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 700;
}

.mainContainer {
  margin-bottom: 40px;
}
.containerRequest {
  border-bottom: 1px solid #3f88c1;
}

.containerRequest h2 {
  font-size: 24px;
  color: #98c4e6;
}
.containerRequest h2 strong {
  color: #fff;
}

hr {
  height: 2px;
  border-radius: 5px;
  background: #3f88c1;
}

.buttonsContainer button {
  background: none;
  border: none;
  color: #fff;
  outline: none;
  transition: all 0.2s linear;
  padding: 10px;
  border-radius: 6px;
  margin-right: 20px;
  height: 40px;
}

.buttonsContainer button:hover {
  background: #3f88c1;
}

.btnFinish {
  background: #25d366;
  border-radius: 6px;
  border: none;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  outline: none;
  transition: all 0.2s linear;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnFinish:hover {
  background: #13ad4c;
}

.backPage {
  background: none;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  padding: 0 20px;
  transition: all 0.2s linear;
  border-radius: 10px;
}
.backPage:hover {
  background: #3f88c1;
}
</style>
